import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import simg from '../../images/projects/wordpress/1.jpg'
import simg2 from '../../images/projects/wordpress/2.jpg'
import simg3 from '../../images/projects/wordpress/3.jpg'
import simg4 from '../../images/projects/wordpress/4.jpg'
import simg5 from '../../images/projects/wordpress/5.jpg'
import simg6 from '../../images/projects/wordpress/6.jpg'
import simg7 from '../../images/projects/wordpress/7.jpg'
import simg8 from '../../images/projects/wordpress/8.jpg'
import simg9 from '../../images/projects/wordpress/9.jpg'
import simg10 from '../../images/projects/wordpress/10.jpg'
import simg11 from '../../images/projects/wordpress/11.jpg'

import himg from '../../images/projects/html/1.jpg'
import himg2 from '../../images/projects/html/2.jpg'
import himg3 from '../../images/projects/html/3.jpg'
import himg4 from '../../images/projects/html/4.jpg'
import himg5 from '../../images/projects/html/img-8.jpg'
import himg6 from '../../images/projects/html/5.jpg'
import himg7 from '../../images/projects/html/img-2.jpg'
import himg8 from '../../images/projects/html/img-1.jpg'
import himg9 from '../../images/projects/html/img-10.jpg'
import himg11 from '../../images/projects/html/11.jpg'

import rimg from '../../images/projects/react/1.jpg'
import rimg2 from '../../images/projects/react/2.jpg'
import rimg3 from '../../images/projects/react/3.jpg'
import rimg5 from '../../images/projects/react/6.jpg'
import rimg6 from '../../images/projects/react/7.jpg'
import rimg8 from '../../images/projects/react/5.jpg'
import rimg9 from '../../images/projects/react/8.jpg'
import rimg10 from '../../images/projects/react/9.jpg'
import rimg11 from '../../images/projects/react/10.jpg'
import rimg12 from '../../images/projects/react/4.jpg'
import rimgwed from '../../images/projects/react/11.jpg'


const AllPrjects = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const Tabs = [
        {
            Id: '1',
            tabItem: 'WordPress',
        },
        {
            Id: '2',
            tabItem: 'HTML',
        },
        {
            Id: '3',
            tabItem: 'React',
        }
    ]
    const Projects = [
        {
            Id: '1',
            mainTitle: 'wordpress',
            lists:[
                {
                    Id: '1',
                    sImg:simg,
                    sTitle: 'Barristar – Law, Lawyer and Attorney WordPress Theme',
                    link:'https://1.envato.market/barristar-wp'
                },
                {
                    Id: '2',
                    sImg:simg2,
                    sTitle: 'Canun - Lawyer & Attorney WordPress Theme',
                    link:'https://1.envato.market/canun-law',
                },
                {
                    Id: '3',
                    sImg:simg7,
                    sTitle: 'Consultar - Consulting Business WordPress Theme',
                    link:'https://1.envato.market/consultar-wp'
                },
                {
                    Id: '11',
                    sImg:simg11,
                    sTitle: 'Loveme - Wedding & Wedding Planner WordPress Theme',
                    link:'https://1.envato.market/loveme-wp'
                },
                {
                    Id: '9',
                    sImg:simg5,
                    sTitle: 'Lavelo - Wedding WordPress Theme',
                    link:'https://1.envato.market/lavelo-wp'
                },
                {
                    Id: '4',
                    sImg:simg8,
                    sTitle: 'Varaus - Hotel Booking WordPress Theme',
                    link:'https://1.envato.market/varaus-wp'
                },
                {
                    Id: '5',
                    sImg:simg9,
                    sTitle: 'Nasarna - NonProfit Charity WordPress Theme',
                    link:'https://1.envato.market/nasarna-wp'
                },
                
                {
                    Id: '6',
                    sImg:simg6,
                    sTitle: 'Shiptar - Transport & Logistics WordPress Theme',
                    link:'https://1.envato.market/shiptar-wp'
                },
                {
                    Id: '7',
                    sImg:simg3,
                    sTitle: 'Hostar - Web Hosting WordPress Theme',
                    link:'https://1.envato.market/hostar-wp'
                },
                {
                    Id: '8',
                    sImg:simg4,
                    sTitle: 'Apeix - Car Repair Service & Auto Mechanic WordPress Theme',
                    link:'https://1.envato.market/apeix-wp'
                },
                {
                    Id: '10',
                    sImg:simg10,
                    sTitle: 'Tmexco - Digital Marketplace WooCommerce Theme',
                    link:'https://1.envato.market/tmexco-wp'
                },
                
                
            ]
        },
        {
            Id: '2',
            mainTitle: 'HTML',
            lists:[
                {
                    Id: '1',
                    sImg:himg,
                    sTitle: 'Consultar - Consulting Business HTML5 Template',
                    link:'https://1.envato.market/consultar-html'
                },
                {
                    Id: '2',
                    sImg:himg2,
                    sTitle: 'canun – Lawyer and Attorney HTML5 Template',
                    link:'https://1.envato.market/canun-html'
                },
                {
                    Id: '11',
                    sImg:himg11,
                    sTitle: 'Loveme - Wedding & Wedding Planner HTML5 Template',
                    link:'https://1.envato.market/loveme-html'
                },
                {
                    Id: '3',
                    sImg:himg3,
                    sTitle: 'Varaus - Hotel Booking HTML5 Template',
                    link:'https://1.envato.market/varaus-html'
                },
                {
                    Id: '4',
                    sImg:himg4,
                    sTitle: 'Cleanco - Plumbing Services HTML5 Template',
                    link:'https://1.envato.market/cleanco-html'
                },
                {
                    Id: '5',
                    sImg:himg5,
                    sTitle: 'Follio - Multipurpose Portfolio HTML5 Template',
                    link:'https://1.envato.market/follio-html'
                },
                {
                    Id: '7',
                    sImg:himg6,
                    sTitle: 'Nasarna - Charity Nonprofit HTML5 Template',
                    link:'https://1.envato.market/nasarna-html'
                },
                {
                    Id: '8',
                    sImg:himg7,
                    sTitle: 'Barristar – Lawyers Attorneys and Law Firm HTML Template',
                    link:'https://1.envato.market/barristar-html'
                },
                {
                    Id: '9',
                    sImg:himg8,
                    sTitle: 'Lavelo - Wedding HTML5 Template',
                    link:'https://1.envato.market/lavelo-html'
                },
                {
                    Id: '10',
                    sImg:himg9,
                    sTitle: 'Shifter – Transport & Logistic HTML Template',
                    link:'https://1.envato.market/shifter-html'
                },
            ]
        },
        {
            Id: '3',
            mainTitle: 'React',
            lists:[
                {
                    Id: '88',
                    sImg:rimgwed,
                    sTitle: 'Loveme - Wedding & Planner React Template',
                    link:'https://themeforest.net/item/loveme-wedding-planner-react-template/35120036?s_rank=3'
                },
                {
                    Id: '1',
                    sImg:rimg,
                    sTitle: 'Consultar - Business Consulting React Template',
                    link:'https://1.envato.market/consultar-react'
                },
                {
                    Id: '2',
                    sImg:rimg2,
                    sTitle: 'Varaus - Hotel Booking React Template',
                    link:'https://1.envato.market/varaus-react'
                },
                {
                    Id: '3',
                    sImg:rimg3,
                    sTitle: 'Follio - Multipurpose Portfolio React Template',
                    link:'https://1.envato.market/follio-react'
                },
                {
                    Id: '8',
                    sImg:rimg12,
                    sTitle: 'Follio - Multipurpose Portfolio Next Js Template',
                    link:'https://1.envato.market/follio-next'
                },
                {
                    Id: '5',
                    sImg:rimg5,
                    sTitle: 'Lavelo - React Wedding Template',
                    link:'https://1.envato.market/lavelo-react'
                },
                {
                    Id: '9',
                    sImg:rimg8,
                    sTitle: 'Nasarna - Charity React Template',
                    link:'https://1.envato.market/nasarna-react'
                },
                {
                    Id: '4',
                    sImg:rimg6,
                    sTitle: 'Nasarna - Charity Nonprofit Next Js Template',
                    link:'https://1.envato.market/nasarna-next'
                },
                {
                    Id: '10',
                    sImg:rimg9,
                    sTitle: 'Shifter – Transport and Logistics React Template',
                    link:'https://1.envato.market/shiptar-react'
                },
                {
                    Id: '12',
                    sImg:rimg10,
                    sTitle: 'Edefy - React Js Digital Marketplace Template',
                    link:'https://1.envato.market/edefy-react'
                },
                {
                    Id: '11',
                    sImg:rimg11,
                    sTitle: 'Barristar - Next Js Attorney Template',
                    link:'https://1.envato.market/barristar-next'
                },
            ]
        },
        
    ]



    return (

        <section className="wpo-project-section">
            <div className="container">
                <div className="row">
                    <div className="col col-12 project-thumbs">
                        <Nav tabs className="project-thumbs-outer">
                            {Tabs.map((tab, titem) => (
                                <NavItem key={titem}>
                                    <NavLink
                                        className={`project-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                        onClick={() => { toggle(tab.Id); }}
                                    >
                                        {tab.tabItem}
                                        <span className="number">{tab.tabNumber}</span>
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>
                    <div className="col col-12 project-content">
                        <div className="project-content-outer">
                            <TabContent activeTab={activeTab}>
                                {Projects.map((project, Sitem) => (
                                    <TabPane tabId={project.Id} key={Sitem}>
                                        <h2>{project.sTitle}</h2>
                                        <div>
                                            {project.lists.map(m_item => {
                                                return (
                                                    <div key={m_item.Id}>
                                                         <a target="_blank" href={m_item.link}>
                                                            <img src={m_item.sImg} alt="" />
                                                         </a>
                                                         <h2>
                                                            <a target="_blank" href={m_item.link}>
                                                                {m_item.sTitle}
                                                            </a>
                                                         </h2>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default AllPrjects;